import _ from 'lodash';

const MAIN_URL = 'https://dailycashup.com';

const SERIAL_VERSION = 0;
const DAYS_ABBREV = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS_ABBREV = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const CURRENCIES = ['GBP', 'EUR', 'USD'];

// modes
const WEIGH = 'WEIGH';
const COUNT = 'COUNT';
const SKIP_HIDE = 'SKIP_HIDE';
const DISABLE = 'DISABLE';
const MODES = [WEIGH, COUNT, SKIP_HIDE, DISABLE];
const DENOMS_MODE = {
  EUR: {50000: SKIP_HIDE, 20000: SKIP_HIDE},
  GBP: {5000: SKIP_HIDE},
  USD: {},
};

const DENOMINATIONS = {
  EUR: [50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1],
  GBP: [5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1],
  USD: [10000, 5000, 2000, 1000, 500, 200, 100, 50, 25, 10, 5, 1],
};

// TODO: Get bundles automatically from weights
const BUNDLES = {
  EUR: [], 
  GBP: [[2, 1], [10, 5]],
  USD: [[50, 25, 10]],
};

// Currency Symbols
const SYMBOLS = {
  EUR: {sm: '\u00A2', lg: '\u20AC'},
  GBP: {sm: 'p', lg: '\u00A3'},
  USD: {sm: '\u00A2', lg: '\u0024'},
};

const WEIGHTS = {
  EUR: {1: 2.30, 2: 3.06, 5: 3.92, 10: 4.10, 20: 5.74, 50: 7.80, 100: 7.50, 200: 8.50},
  GBP: {1: 3.56, 2: 7.12, 5: 3.25, 10: 6.50, 20: 5.00, 50: 8.00, 100: 8.75, 200: 12},
  USD: {1: 2.500, 5: 5.000, 10: 2.268, 25: 5.670, 50: 11.340, 100: 8.100},
};

const inDevMode = process.env.NODE_ENV === 'development';

const getExtraConfig = () => {
  return ['HIDE_TIPS'];
};

const isBool = v => typeof v === 'boolean';

const isNote = (curr, denom) => !WEIGHTS[curr][denom];

const getLocalCurrency = () => {
  let country
  fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_GEOLOC_API_KEY}`)
    .then(res => res.json())
    .then(payload => country = payload.country_code2);
  return country === 'GB'
    ? CURRENCIES[0]
    : country === 'US'
      ? CURRENCIES[2]
      : CURRENCIES[1];
};

const serializeConfig = (mode, extra, floatAmount, bundles, currency = getLocalCurrency()) => {
  const denominations = DENOMINATIONS[currency];
  const extraConfig = getExtraConfig();
  
  const x = Object.assign({}, mode, extra);
  const keys = [...denominations, ...extraConfig];

  let tR = '';
  keys.forEach(k => {
    tR += isBool(x[k]) ? (x[k] ? 1 : 0) : MODES.indexOf(x[k]);
  });
  tR += `.${floatAmount || '0'}`;
  tR += `.${CURRENCIES.indexOf(currency)}`;
  if (Array.isArray(bundles)) {
    tR += `.${bundles.map(bool => +bool).join('')}`;
  } else {
    if (inDevMode) console.warn('bundles is not array');
  };

  return `${SERIAL_VERSION}.` + tR;
};

const deserializeConfig = serial => {
  const sParts = serial.split('.');
  sParts.shift();
  const s = sParts.join('.');

  const currency = CURRENCIES[sParts[2]];
  if (!currency) return null;

  const denominations = DENOMINATIONS[currency];
  const extraConfig = getExtraConfig();
  const bundlesConfig = BUNDLES[currency];

  const mode = {}, extra = {};
  let floatAmount = '', bundles = [];

  if (
    sParts[0].length !== denominations.length + extraConfig.length || 
    sParts[3].length !== bundlesConfig.length
  ) return null;

  denominations.forEach((k, i) => {
    mode[k] = MODES[s[i]];
  });
  extraConfig.forEach((k, i) => {
    extra[k] = !!(s[i + denominations.length]<<0);
  });
  floatAmount = sParts[1];
  bundles = sParts[3].split('').map(numStr => !!+numStr);

  if(Object.values(mode).filter(Boolean).length !== denominations.length) return null;

  const reserialized = serializeConfig(mode, extra, floatAmount, bundles, currency);
  if(serial !== reserialized) return null;

  return {mode, extra, floatAmount, currency, bundles};
};

// greatest common divisor
const gcd = (a, b) => b ? gcd(b, a % b) : a;

const GCD = (...nums) => {
  if (nums.length === 2) {
    return gcd(nums[0], nums[1]);
  }
  const [a, ...rest] = nums;
  return GCD(a, GCD(...rest));
};

// least common multiple
const lcm = (a, b) => a / gcd(a, b) * b;

const LCM = (...nums) => {
  if (nums.length === 2) {
    return lcm(nums[0], nums[1]);
  }
  const [a, ...rest] = nums;
  return LCM(a, LCM(...rest));
};

// pickMoneyOut with infinite resources
// now works at least for denoms: [10, 25, 50], [1, 2], [5, 10]
const pickMoneyOutInf = (denoms, amount) => {
  const max = Math.max(...denoms);
  const m = LCM(...denoms);
  const r = amount % m;
  const d = (amount - r) / m;

  const value = d ? r + m : r;
  const bObj = denoms.reduce((acc, denom) => {
    const v = Math.ceil(value / denom) * denom;
    acc[denom] = v;
    return acc;
  }, {});
  const res = pickMoneyOut(bObj, value);
  const rest = d ? (d - 1) * m : 0;
  
  res.denoms[max] = res.denoms[max] ? res.denoms[max] : 0;
  res.denoms[max] += rest;
  res.value = res.value + rest;
  return res;
};

// rounding money to existing level
const roundMoney = (denoms, money) => {
  const den = GCD(...denoms);
  const r = Math.round(money / den) * den;
  return pickMoneyOutInf(denoms, r).value;
};

const calculateMoney = (byWeight, denom, value, currency = getLocalCurrency(), bundle) => {
  if (byWeight) {
    const weights = WEIGHTS[currency];
    let rawValue = value / weights[denom];
    return bundle 
      ? roundMoney(bundle, rawValue * denom) 
      : Math.round(rawValue) * denom;
  };
  return value * denom;
};

//- splitBundle was also considered for function name
const unpackBundle = (bundle, money) => pickMoneyOutInf(bundle, money).denoms;

const pickMoneyOutBundled = (denoms, value, activeBundles) => {
  let tDenoms = Object.keys(denoms).reduce((a,k) => {
    if (denoms[k].bundled) {
      const bundle = activeBundles[k];
      const unpacked = unpackBundle(bundle, denoms[k].amount);
      a = {...a, ...unpacked};
    } else {
      a[k] = denoms[k].amount;
    };
    return a;
  }, {});

  const res = pickMoneyOut(tDenoms, value);

  const denomsObj = Object.keys(denoms).reduce((a,k) => {
    const amount = res.denoms[k];
    if(denoms[k].bundled) {
      let sum = 0;
      const bundle = activeBundles[k];
      bundle.forEach(d => sum += res.denoms[d] ? res.denoms[d] : 0);
      if (sum) {
        a[k] = {amount: sum}; 
        a[k].bundled = true;
      };
    } 
    else if(amount) {
      a[k] = {amount};
    };
    return a;
  }, {});
  
  return {denoms: denomsObj, value: res.value}
};

const pickMoneyOut = (moneyObj, amount) => {
  // ini
  const tR = {denoms: {}, value: 0};
  const sortedDenoms = Object.keys(moneyObj).sort((a,b) => a-b).reverse();

  // first pass
  let beforeLast = sortedDenoms[0], last;
  for(let k of sortedDenoms) {
    for(let coins = moneyObj[k] / k; tR.value < amount && coins--; last = k){
      tR.value += k*1;
      if(!tR.denoms[k]) tR.denoms[k] = 0;
      tR.denoms[k] += k*1;
    }
    if(tR.value < amount) beforeLast = k;
  };

  // perfect or too little available
  if(tR.value <= amount) return tR;

  // step `a` back?
  const a = {
    denoms: _.pickBy({...tR.denoms, [last]: tR.denoms[last] - last}, v => v>0),
    value: tR.value - last,
  };

  // too much cont'd -> counting from lower denominations
  const b = pickMoneyOut(
    _.pickBy(moneyObj, ((v, k) => k*1 < last)),
    amount - tR.value + last*1
  );

  const c = b.value ? {
    denoms: _.pickBy({...tR.denoms, ...b.denoms, [last]: tR.denoms[last] - last}, v => v>0),
    value: tR.value + b.value - last,
  } : a;

  // TODO: add checking which of a, b or d is best solution
  if(c.value === amount) return c;
  
  // counting without 1 piece of one before last or the highest denomination
  const d = sortedDenoms.length > 1 ? pickMoneyOut(
    _.pickBy({...moneyObj, [beforeLast]: moneyObj[beforeLast] - beforeLast}, v => v>0),
    amount
  ) : undefined;

  return (d?.value === amount) ? d : c;
};

const copyToClipboard = text => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const formatAMPM = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ampm;
};

const getDateTimeString = date => (
  DAYS_ABBREV[date.getDay()] +
  ', ' + date.getDate() + ' ' +
  MONTHS_ABBREV[date.getMonth()] + ' ' +
  date.getFullYear() + ' at ' +
  formatAMPM(date)
);

const formatPriceCommon = (val, sym = '£') =>
  (val < 0 ? '-' : '') + sym +
  Math.abs(val / 100 << 0).toString().split('').reverse().join('')
  .match(/.{1,3}/g).join().split('').reverse().join('') +
  '.' + (Math.abs(val) % 100).toString().padStart(2, '0');

const formatPrice = (value, currency, format = 'lg') => {
  const symbols = SYMBOLS[currency];

  if (format === 'auto') format = Math.abs(value) < 100 ? 'sm' : 'lg';
  const sym = symbols[format];
  let tR = '';
  if (format === 'lg') {
    tR = formatPriceCommon(value, sym);
    if (tR.slice(tR.length - 2) === '00') tR = tR.slice(0, -3);
  } else {
    tR = value.toLocaleString('en-UK') + symbols.sm;
  };
  return tR;
};

const getSummaryStringsArr = (sumObj, denomsToShow, withWeight, currency = getLocalCurrency(), activeBundles) => {
  const denominations = DENOMINATIONS[currency];
  const weights = WEIGHTS[currency];
  const tR = denominations.reduce((acc, denom) => {
    let str = 'skip-this';
    if(sumObj[denom] && sumObj[denom].amount) {
      str = `${formatPrice(sumObj[denom].amount, currency)} `;
      if (sumObj[denom].bundled) {
        let weight = sumObj[denom].amount / denom * weights[denom];
        weight = Math.round((weight + Number.EPSILON) * 10) / 10;
        const prices = activeBundles.denoms[denom].map(d => formatPrice(d, currency, 'auto'))
        str += `(${withWeight ? `${weight}g of ` : ''}${prices.join('/')})`;
      }
      else {
        const label = formatPrice(denom, currency, 'auto');
        str += `(${sumObj[denom].amount / denom} × ${label})`;
      }
    }
    else if (denomsToShow.includes(denom.toString())) str = ''; 
    return str === 'skip-this' ? acc : [...acc, str];
  }, []);
  return tR;
};

export {
  WEIGHTS,
  BUNDLES,
  COUNT,
  CURRENCIES,
  DAYS_ABBREV,
  DENOMINATIONS,
  DENOMS_MODE,
  DISABLE,
  MAIN_URL,
  MODES,
  MONTHS_ABBREV,
  SKIP_HIDE,
  SYMBOLS,
  WEIGH,
  calculateMoney,
  copyToClipboard,
  deserializeConfig,
  formatAMPM,
  formatPrice,
  getLocalCurrency,
  getDateTimeString,
  getExtraConfig,
  getSummaryStringsArr,
  inDevMode,
  isNote,
  pickMoneyOut,
  pickMoneyOutBundled,
  pickMoneyOutInf,
  serializeConfig,
};

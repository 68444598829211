import {useState} from 'react';
import {useDispatch} from 'react-redux';

import _ from 'lodash';

import {
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {FloatChangeDialog} from './FloatChange';
import {setSnackbar} from '../../features/mainSlice';
import {colorNames, theme} from '../../helpers/theme';

import {
  copyToClipboard,
  formatPrice,
  getDateTimeString,
  getSummaryStringsArr,
} from '../../helpers/common';

import CardWithCopyButton from './CardWithCopyButton';

const FloatChange = () => {
  const {palette} = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <>
      <ButtonBase onClick={handleOpenDialog}>
        <Typography variant="body1" style={{color: palette.primary.main}} component="span">
          set a custom float
        </Typography>
      </ButtonBase>
      <FloatChangeDialog
        open={openDialog}
        onClose={handleCloseDialog}
      />
    </>
  );
};

const Summary = ({
  activeBundles,
  chooseFloatAmount,
  currency,
  floatDenoms,
  floatSpecified,
  floatSum,
  isPosKey,
  takingsDenoms,
  takingsSum,
  totalDenoms,
  totalSum,
}) => {
  const dispatch = useDispatch();

  const below600px = useMediaQuery('(max-width:599px)', {noSsr: true});
  const totalStrArr = getSummaryStringsArr(totalDenoms, [], false, currency, activeBundles);
  const floatStrArr = getSummaryStringsArr(floatDenoms, [], true, currency, activeBundles);
  const takingsStrArr = getSummaryStringsArr(takingsDenoms, [], true, currency, activeBundles);
  const denomsToShow = _.reduce(totalDenoms, (acc, denomObj, denom) =>
    denomObj.amount ? [...acc, denom] : acc, []);

  const handleCopyClick = () => {
    copyToClipboard(
      `${
        getDateTimeString(new Date())
      }\r\nTotal ${
        formatPrice(totalSum, currency)
      }\r\n${
        totalStrArr.join('\r\n')
      }\r\n\r\nFloat ${
        formatPrice(floatSum, currency)
      }\r\n${
        floatStrArr.join('\r\n')
      }\r\n\r\nTakings ${
        formatPrice(takingsSum, currency)
      }\r\n${
        takingsStrArr.join('\r\n')
      }`
    );
    dispatch(setSnackbar({
      open: true,
      type: 'success',
      message: 'Copied into the clipboard.',
    }));
  };

  return (
    <>
      <div style={styles.summaryContainer(below600px)}>
        <CardWithCopyButton
          activeBundles={activeBundles}
          currency={currency}
          title={'Total'}
          sum={totalSum}
          sumObj={totalDenoms}
          denomsToShow={denomsToShow}
        />
        {floatSpecified ? (
          <>
            <CardWithCopyButton
              activeBundles={activeBundles}
              currency={currency}
              title={'Float'}
              sum={floatSum}
              sumObj={floatDenoms}
              denomsToShow={denomsToShow}
              isPosKey={isPosKey}
            />
            <CardWithCopyButton
              activeBundles={activeBundles}
              currency={currency}
              title={'Takings'}
              sum={takingsSum}
              sumObj={takingsDenoms}
              denomsToShow={denomsToShow}
            />
          </>
        ) : (
          <div style={styles.noFloatSection}>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h6">Float not specified</Typography>
              <Typography variant="body1">Choose from quick options below:</Typography>
              <div style={styles.floatButtons}>
                {['5000', '10000', '15000'].map(v => (
                  <Button
                    variant="text"
                    onClick={chooseFloatAmount(v)}
                    style={{color: colorNames.black}}
                    key={v}
                  >
                    {formatPrice(v, currency)}
                  </Button>
                ))}
              </div>
              <div style={styles.customFloatLink}>
                <Typography variant="body1">or</Typography>
                <FloatChange />
              </div>
            </div>
          </div>
        )
        }
      </div>
      {(floatSpecified && !isPosKey) &&
        <Button onClick={handleCopyClick}>Copy all</Button>
      }
    </>
  );
};

const styles = {
  summaryContainer: below600px => ({
    padding: `${theme.gutterBig}px 0`,
    display: 'flex',
    flexDirection: below600px ? 'column' : 'row',
    flex: 1,
    columnGap: theme.gutterMedium,
    rowGap: theme.gutterMedium,
  }),
  noFloatSection: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  floatButtons: {
    display: 'flex',
    columnGap: theme.gutterBig,
    justifyContent: 'center',
  },
  customFloatLink: {
    display: 'flex',
    columnGap: theme.gutterSmall,
    justifyContent: 'center',
  },
};

export default Summary;

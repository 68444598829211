import {createSlice} from '@reduxjs/toolkit';

import {
  BUNDLES,
  COUNT,
  DENOMINATIONS,
  DENOMS_MODE,
  getLocalCurrency,
  getExtraConfig,
  serializeConfig,
} from '../helpers/common';

const getCurrencyConfig = (currency = getLocalCurrency()) => {
  const floatAmount = '0';
  const bundlesConfig = BUNDLES[currency];
  const denoms = DENOMINATIONS[currency];
  const denomsMode = DENOMS_MODE[currency];
  const extraConfig = getExtraConfig();

  const mode = denoms.reduce((acc, k) => {
    if (denomsMode.hasOwnProperty(k)) {
      acc[k] = denomsMode[k];
      return acc;
    }

    acc[k] = COUNT;
    return acc;
  }, {});

  const extra = extraConfig.reduce((acc, k) => {
    acc[k] = false;
    return acc;
  }, {});

  const bundles = bundlesConfig.map(() => false);

  return {mode, extra, bundles, floatAmount, currency};
};

const getDefaultState = () => {
  const {mode, extra, bundles, floatAmount, currency} = getCurrencyConfig(getLocalCurrency())
  return {
    snackbar: {
      open: false,
      type: 'success',
      message: '',
    },
    bundles,
    currency,
    mode,
    floatAmount,
    extra,
    defaultString: serializeConfig(mode, extra, floatAmount, bundles, currency),
  };
};

export const mainSlice = createSlice({
  name: 'main',
  initialState: getDefaultState(),
  reducers: {
    setCurrency: (state, action) => {
      const config = getCurrencyConfig(action.payload);
      for (const key in config) {
        state[key] = config[key];
      }
    },
    setSnackbar: (state, action) => {
      const {open, type, message} = action.payload;
      state.snackbar.open = open;
      state.snackbar.type = type;
      state.snackbar.message = message;
    },
    setMode: (state, action) => {
      state.mode[action.payload.id] = action.payload.value;
    },
    setFloatAmount: (state, action) => {
      state.floatAmount = action.payload;
    },
    setExtra: (state, action) => {
      state.extra[action.payload.id] = action.payload.value;
    },
    setBundle: (state, action) => {
      state.bundles[action.payload.idx] = action.payload.value;
    },
    batchSetConfig: (state, action) => {
      state.mode = action.payload.mode;
      state.extra = action.payload.extra;
      state.floatAmount = action.payload.floatAmount;
      state.bundles = action.payload.bundles;
      state.currency = action.payload.currency;
    },
    DANGEROUSPurge: state => getDefaultState(),
  },
});

export const selectBundles = state => state.main.bundles;
export const selectCurrency = state => state.main.currency;
export const selectExtra = state => state.main.extra;
export const selectFloatAmount = state => state.main.floatAmount;
export const selectMode = state => state.main.mode;
export const selectSnackbar = state => state.main.snackbar;
export const selectDefaultString = state => state.main.defaultString;

export const selectSerializedConfig = state => {
  return serializeConfig(state.main.mode, state.main.extra, state.main.floatAmount, state.main.bundles, state.main.currency);
};

export const selectIsSerialNonstandard = state => {
  return serializeConfig(state.main.mode, state.main.extra, state.main.floatAmount, state.main.bundles, state.main.currency) !== state.main.defaultString;
};

export const {
  DANGEROUSPurge,
  setBundle,
  setCurrency,
  setExtra,
  setFloatAmount,
  setMode,
  setSnackbar,
  batchSetConfig,
} = mainSlice.actions;

export default mainSlice.reducer;

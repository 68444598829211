/** @jsxImportSource @emotion/react */
import {theme} from './helpers/theme';

import {Button, Typography} from '@mui/material';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  return (
    <div css={styles.root}>
      <Typography variant="h6" css={styles.errorContent}>
        Oh my&hellip; It looks like an error.
      </Typography>
      <Typography css={styles.errorContent}>{error.message}</Typography>
      <div css={styles.actionArea}>
        <Typography css={{flex: 1}}>
          Usually it&apos;s a great idea to check out the console&hellip;
        </Typography>
        <Button variant="contained" onClick={resetErrorBoundary}>
          Flush SETS persistor
        </Button>
      </div>
    </div>
  );
};

const styles = {
  root: {
    padding: 30,
    flexGrow: 1,
  },
  errorContent: {
    padding: theme.gutter,
  },
  actionArea: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.gutter,
  },
};

export default ErrorFallback;

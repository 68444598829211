import {createStore, combineReducers} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {devToolsEnhancer} from 'redux-devtools-extension';

import mainReducer from '../features/mainSlice';

const rootReducer = combineReducers({
  main: mainReducer,
});

const persistConfig = {
  key: 'cashUp',
  storage,
  whitelist: ['main'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, devToolsEnhancer());
const persistor = persistStore(store);

export {store, persistor};

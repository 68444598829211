import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ErrorBoundary} from 'react-error-boundary';

import './index.css';
import App from './App';
import Snackbar from './components/Snackbar';
import {store, persistor} from './app/store';
import {DANGEROUSPurge} from './features/mainSlice';
import ErrorFallback from './RootErrorFallback';

const flushStore = () => store.dispatch(DANGEROUSPurge());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={flushStore}>
          <Snackbar />
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

import {useDispatch, useSelector} from 'react-redux';

import {Alert, Snackbar as MuiSnackbar} from '@mui/material';

import {selectSnackbar, setSnackbar} from '../features/mainSlice';

const Snackbar = () => {
  const dispatch = useDispatch();
  const {open, type, message} =
    useSelector(selectSnackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      setSnackbar({open: false, type, message}),
    );
  };

  return (
    <div>
      <MuiSnackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={type}
          severity={type}
        >
          {message}
        </Alert>
      </MuiSnackbar>
    </div>
  );
};

export default Snackbar;

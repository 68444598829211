import {Link} from 'react-router-dom';

import {AppBar as MuiAppBar, IconButton, Typography} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import {Logo} from '../helpers/Icons';
import {theme} from '../helpers/theme';

const AppBar = () => (
  <MuiAppBar position="static">
    <div style={styles.insideFrame}>
      <Link to="/">
        <div style={{width: 230, marginLeft: theme.gutter*(-0.25)}}>
          <Logo />
        </div>
        <Typography variant="body2" style={{marginTop: theme.gutter*(-1)}}>
          FREE & EASY CASH COUNTING
        </Typography>
      </Link>
      <Link to="/config">
        <IconButton>
          <SettingsIcon style={{ color: 'white' }} />
        </IconButton>
      </Link>
    </div>
  </MuiAppBar>
);

const styles = {
  insideFrame: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    padding: theme.gutterBig,
  },
};

export default AppBar;

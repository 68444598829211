import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  Button,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import {Edit as EditIcon, Clear as ClearIcon} from '@mui/icons-material';

import {
  selectCurrency,
  selectFloatAmount,
  setFloatAmount, 
} from '../../features/mainSlice';
import {formatPrice} from '../../helpers/common';
import {theme} from '../../helpers/theme';

const FloatChangeDialog = ({open, onClose}) => {
  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency);
  const float = useSelector(selectFloatAmount);

  const [tempFloat, setTempFloat] = useState(float);

  const changeFloatAmount = e => setTempFloat(e.target.value);
  const clearFloatAmount = () => setTempFloat('');

  const handleSubmit = () => {
    dispatch(setFloatAmount(tempFloat));
    onClose();
  };

  const handleCancel = () => {
    setTempFloat(float);
    onClose();
  };

  return (
    <Dialog style={styles.popup} fullWidth open={open} onClose={handleCancel}>
      <DialogTitle>Float</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          value={tempFloat}
          onChange={changeFloatAmount}
          style={styles.input}
          type="number"
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  {formatPrice(tempFloat, currency)}
                </InputAdornment>
                {tempFloat &&
                  <IconButton onClick={clearFloatAmount}>
                    <ClearIcon />
                  </IconButton>
                }
              </> 
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Ok
        </Button>         
      </DialogActions>
    </Dialog>
  );
};

const FloatChange = () => {
  const [openDialog, setOpenDialog] = useState(false);
  
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <>
      <IconButton style={styles.editButton} onClick={handleOpenDialog}>
        <EditIcon />
      </IconButton>
      <FloatChangeDialog 
        open={openDialog}
        onClose={handleCloseDialog}
      />
    </>
  );
};

const styles = {
  editButton: {
    position: 'absolute',
    top: theme.gutterMedium,
    right: 50,
  },
  input: {
    width: '100%',
  },
  popup: {
    maxWidth: 400,
    margin: 'auto',
  },
};

export {FloatChangeDialog, FloatChange};

import {useDispatch} from 'react-redux';

import {
  Card,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import CopyIcon from '@mui/icons-material/ContentCopy';

import {FloatChange} from './FloatChange';
import {setSnackbar} from '../../features/mainSlice';
import {colorNames, theme} from '../../helpers/theme';

import {
  copyToClipboard,
  formatPrice,
  getDateTimeString,
  getSummaryStringsArr,
} from '../../helpers/common';

const CardWithCopyButton = ({
  activeBundles,
  currency,
  denomsToShow,
  isPosKey,
  sum,
  sumObj,
  title,
}) => {
  const dispatch = useDispatch();
  
  const below600px = useMediaQuery('(max-width:599px)', {noSsr: true});
  const isMinus = sum < 0;
  const summaryStringsArr = getSummaryStringsArr(sumObj, denomsToShow, title !== 'Total', currency, activeBundles);

  const handleCopyClick = () => {
    const arrToCopy = summaryStringsArr.filter(str => str !== '');
    copyToClipboard(
      `${getDateTimeString(new Date())}\r\n${title} ${formatPrice(sum, currency)}\r\n${arrToCopy.join('\r\n')}`
    );
    dispatch(setSnackbar({
      open: true,
      type: 'success',
      message: 'Copied into the clipboard.',
    }));
  };

  return (
    <Card style={styles.card}>
      <IconButton
        style={styles.copyButton}
        onClick={handleCopyClick}
      >
        <CopyIcon />
      </IconButton>
      {title === 'Float' && !isPosKey &&
        <FloatChange />
      }
      <Typography variant="body1" style={styles.sumTitle(isMinus)}>
        {title}
      </Typography>
      <Typography variant="h6" style={styles.sum(isMinus)}>
        {formatPrice(sum, currency)}
      </Typography>
      {summaryStringsArr.map((str, ind) => (
        <Typography variant="body1" key={str + ind}>
          {str}
          {!below600px && <br />}
        </Typography>
      ))}
    </Card>
  );
};

const styles = {
  card: {
    flex: 1,
    position: 'relative',
    padding: theme.gutterBig,
  },
  copyButton: {
    position: 'absolute',
    top: theme.gutterMedium,
    right: theme.gutter,
  },
  sumTitle: isMinus => ({
    textTransform: 'uppercase',
    color: isMinus ? colorNames.red : null,
  }),
  sum: isMinus => ({
    marginBottom: theme.gutter,
    fontWeight: 'bold',
    color: isMinus ? colorNames.red : null,
  }),
};

export default CardWithCopyButton;

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';

import {useSelector} from 'react-redux';

import AppBar from './components/AppBar';

import Config from './pages/Config';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
import Home from './pages/Home';
// import HowTo from './pages/HowTo';
import {theme} from './helpers/theme';

import {
  selectIsSerialNonstandard,
  selectSerializedConfig,
} from './features/mainSlice.js';

const MainAppFrame = ({component: Component}) => {
  const {key} = useParams();
  const isPosKey = key && key === process.env.REACT_APP_POS_KEY;

  return (
    <div style={styles.mainAppFrame}>
      <div>
        {!isPosKey && <AppBar />}
        <div style={styles.insideFrame}>
          <Component isPosKey={isPosKey} />
        </div>
      </div>
      {!isPosKey && <Footer />}
    </div>
  );
};

const App = () => {
  const serial = useSelector(selectSerializedConfig);
  const serialCustom = useSelector(selectIsSerialNonstandard);

  return (
    <Router>
      <Routes>
        <Route 
          path="/"
          element=
          {
            serialCustom ?
            (<Navigate to={`/${serial}`} />) :
            (<MainAppFrame component={Home} />)
          }
        />
        <Route 
          path="/contact-us"
          element={<MainAppFrame component={ContactUs} />}
        />
        {/*
        <Route path="/how-to">
          <MainAppFrame component={HowTo} />
        </Route>
        */}
        <Route 
          path="/config"
          element={<MainAppFrame component={Config} />}
        />
        <Route 
          path="/:serial/:key"
          element={<MainAppFrame component={Home} />}
        />
        <Route 
          path="/:serial"
          element={<MainAppFrame component={Home} />}
        />
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
};

const styles = {
  mainAppFrame: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  insideFrame: {
    maxWidth: 800,
    margin: 'auto',
    padding: theme.gutterBig,
  },
};

export default App;

import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {setSnackbar} from '../features/mainSlice';
import {theme} from '../helpers/theme.js'
import {
  MAIN_URL,
  inDevMode,
} from '../helpers/common';

const SUBJECT_OPT = [
 'get in touch',
 'report a bug',
 'suggest an improvement',
 'other',
];

const prepareData = (subject, message, email) => {
  const tR = {subject, message};
  if(email) tR.email = email;
  return tR;
};

const ContactUs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [subOpt, setSubOpt] = useState(location.state &&
    location.state.report ? SUBJECT_OPT[1] : '');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [emailDialog, setEmailDialog] = useState(false);

  const smScreen = useMediaQuery('(max-width:480px)', {noSsr: true});

  const handleSubjectChange = e => setSubOpt(e.target.value);
  const handleMessageChange = e => setMessage(e.target.value);
  const handleCloseDialog = () => setEmailDialog(false);
  const handleEmailChange = e => setEmail(e.target.value);
  const handleSend = () => {
    if(!email && emailDialog === false) {
      setEmailDialog(true);
    } else {
      setEmailDialog(false);
      fetch(`${MAIN_URL}/api/contact.php`, {
        method: 'POST',
        body: JSON.stringify(prepareData(subOpt, message, email))
      }).then(d => d.json()).then(d => {
        if(d !== 'ok') throw new Error('not_ok');
        navigate('/');
        dispatch(setSnackbar({
          open: true,
          type: 'success',
          message: 'Your message has been sent.',
        }));
      }).catch(e => {
        if(inDevMode) console.error(e);
        dispatch(setSnackbar({
          open: true,
          type: 'error',
          message: 'Your message could not be sent at this time.',
        }));
      });
    }
  };

  return (
    <div>
      <div style={styles.box(smScreen)}>
        <Typography style={{margin: `${theme.gutterBig * 2}px 0`}}
          variant="body1">How can we help you today?</Typography>
      </div>
      <div style={styles.box(smScreen)}>
        <Typography variant="subtitle1" style={styles.title} component="div">
          <span>Subject</span>
          <span style={{color: 'red'}}>*</span>
        </Typography>
        <Select size="small" style={styles.placeholders} value={subOpt} onChange={handleSubjectChange}>
          {SUBJECT_OPT.map(opt => (
            <MenuItem key={opt} value={opt}>{opt}</MenuItem>
          ))}
        </Select>
      </div>
      <div style={styles.box(smScreen)}>
        <Typography variant="subtitle1" style={styles.title} component="div">
          <span>Message</span>
          <span style={{color: 'red'}}>*</span>
        </Typography>
        <TextField
          multiline
          style={styles.placeholders}
          rows={6}
          value={message}
          onChange={handleMessageChange}
        />
      </div>
      <div style={styles.box(smScreen)}>
        <Typography variant="subtitle1" style={styles.title}>Your email</Typography>
        <TextField
          style={styles.placeholders}
          size="small"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div style={styles.box(smScreen)}>
        <Button
          size="large"
          variant="contained"
          style={styles.btn(smScreen)}
          disabled={!message || !subOpt}
          onClick={handleSend}>Send</Button>
        </div>
      <Dialog open={emailDialog} onClose={handleCloseDialog}>
        <DialogTitle>How anonymous&hellip;</DialogTitle>
        <DialogContent>Without your email address we will not be able to contact you. Is that what you want?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Add email</Button>
          <Button onClick={handleSend}>Send anyway</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = {
  box: smScreen => ({
    display: 'flex',
    flexDirection: smScreen && 'column',
    margin: `${theme.gutterBig}px 0`,
    justifyContent: smScreen ? 'center' : 'flex-start',
  }),
  title: {
    width: 150,
  },
  placeholders: {
    width: '100%',
  },
  btn: smScreen => ({
    width: 100,
    margin: smScreen ? 'auto' : null
  }),
}

export default ContactUs;

import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import _ from 'lodash';

import {Typography, useMediaQuery} from '@mui/material';

import Catch from '../components/Home/Catch';
import HeaderWithTopTips from '../components/Home/HeaderWithTopTips';
import OneColumn from '../components/Home/OneColumn';
import Summary from '../components/Home/Summary';
import {
  batchSetConfig,
  selectBundles,
  selectCurrency,
  selectExtra,
  selectFloatAmount,
  selectMode,
} from '../features/mainSlice';
import {theme} from '../helpers/theme';

import {
  BUNDLES,
  DENOMINATIONS,
  calculateMoney,
  deserializeConfig,
  getLocalCurrency,
  isNote,
  pickMoneyOutBundled,
  serializeConfig,
} from '../helpers/common';

const getInitData = (mode, bundlesState, currency = getLocalCurrency()) => {
  const bundles = BUNDLES[currency];
  const toSkip = bundlesState.reduce( (acc, turnOn, idx) => {
    if (turnOn) {
      const bundle = [...bundles[idx]];
      const minIdx = bundle.indexOf(Math.min(...bundle));
      bundle.splice(minIdx, 1);
      acc.push(...bundle);
    }
    return acc;
  }, []);

  return DENOMINATIONS[currency].reduce((acc, denom) => {
    let tR;
    if (mode[denom] === 'SKIP_HIDE') return acc;
    else if (mode[denom] === 'COUNT') tR = {byWeight: false, value: ''};
    else if (mode[denom] === 'WEIGH') {
      if (toSkip.includes(denom)) return acc;
      tR = {byWeight: true, value: ''};
    }
    else if (mode[denom] === 'DISABLE') tR = {value: 0};
    return {...acc, [denom]: tR}
  }, {});
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const Home = ({isPosKey}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {key, serial} = useParams();
  const bundlesState = useSelector(selectBundles);
  const currency = useSelector(selectCurrency);
  const extra = useSelector(selectExtra);
  const floatAmount = useSelector(selectFloatAmount);
  const mode = useSelector(selectMode);

  const prevSerial = usePrevious(serial);
  const prevCurrency = usePrevious(currency);

  const currencyBundles = BUNDLES[currency];
  const denoms = DENOMINATIONS[currency];
  const newSerial = serializeConfig(mode, extra, floatAmount, bundlesState, currency);

  const activeBundles = {};
  activeBundles.mins = [];
  activeBundles.denoms = bundlesState.reduce((acc, turnOn, idx) => {
    if (turnOn) {
      const min = Math.min(...currencyBundles[idx]);
      activeBundles.mins.push(min);
      acc[min] = [...currencyBundles[idx]];
    }
    return acc;
  }, {});

  const currencyChange = prevCurrency ? prevCurrency !== currency : false;
  // potential vulnerability: serial pasting is falsely detected on each mounting
  // this fixes the bug that causes persistor to overwrite current serial config
  const isSerialEditedByUrl = prevSerial !== serial;

  // set config from url
  if(isSerialEditedByUrl && !currencyChange && serial && serial !== newSerial) {
    if(!deserializeConfig(serial)) window.location.href = '/';
    else dispatch(batchSetConfig(deserializeConfig(serial)));
  };

  const below800px = useMediaQuery('(max-width:799px)', {noSsr: true});

  // possible optimization: runs on each render
  const initData = getInitData(mode, bundlesState, currency);

  const [data, setData] = useState(initData);

  useEffect(() => {
    if ((!isSerialEditedByUrl && serial !== newSerial) || (key && !isPosKey)) {
      navigate(`/${newSerial}`);
    };
    if (currencyChange) {
      setData(initData);
    };
  }, [currencyChange, navigate, key, initData, isPosKey, isSerialEditedByUrl, newSerial, serial]);

  const totalSum = _.reduce(data, (tR, obj, denom) => {
    return tR + calculateMoney(obj.byWeight, denom, obj.value, currency, activeBundles.denoms[denom]);
  }, 0);

  const totalDenoms = denoms.reduce((acc, denom) => {
    if(data[denom] && data[denom].value > 0) {
      acc[denom] = {amount: calculateMoney(data[denom].byWeight, denom, data[denom].value, currency, activeBundles.denoms[denom])};
      if (data[denom].byWeight && activeBundles.mins.includes(denom))
        acc[denom].bundled = true;
    };
    return acc;
  }, {});

  const takingsObj = pickMoneyOutBundled(totalDenoms, totalSum - floatAmount > 0 ? totalSum - floatAmount : 0, activeBundles.denoms);
  const takingsSum = totalSum - floatAmount > 0 ? takingsObj.value : totalSum - floatAmount;
  const takingsDenoms = takingsObj.denoms;

  const floatObj = _.reduce(totalDenoms, (acc, denomObj, denom) => {
    if (takingsDenoms[denom] && (denomObj.amount > takingsDenoms[denom].amount)) {
      acc.denoms[denom] = {amount: denomObj.amount - takingsDenoms[denom].amount};
      acc.value += denomObj.amount - takingsDenoms[denom].amount
    }
    else if (!takingsDenoms[denom]) {
      acc.denoms[denom] = {amount: denomObj.amount};
      acc.value += denomObj.amount;
    }
    if (denomObj.bundled && acc.denoms[denom]) acc.denoms[denom].bundled = true;
    return acc;
  }, {denoms: {}, value: 0});

  const changeMode = denom => () => {
    const changeBundled  = (...bundle) => {
      if (!bundle.includes(denom)) return;
      const minDenom = Math.min(...bundle);
      // denoms are bundled -> unbundle
      if (data[denom].byWeight && denom === minDenom) {
        bundle.forEach(d => {
          if (d !== minDenom) newData[d] = {byWeight: false, value: ''};
        });
      }
      // denoms are not bundled -> bundle
      else if (!data[denom].byWeight) {
        bundle.forEach(d => {
          if (d !== minDenom) delete newData[d];
          else newData[minDenom] = {byWeight: true, value: ''};
        });
      };
    };

    const newData = _.cloneDeep(data);
    newData[denom] = {byWeight: !data[denom].byWeight, value: ''};
    activeBundles.mins.forEach(minDenom => {
      const bundle = activeBundles.denoms[minDenom];
      changeBundled(...bundle);
    });

    setData(newData);
  };

  const handleInputValChange = key => e => {
    const newData = _.cloneDeep(data);
    newData[key].value = e.target.value;
    setData(newData);
  };

  const clearInput = key => () => {
    const newData = _.cloneDeep(data);
    newData[key].value = '';
    setData(newData);
  };

  const chooseFloatAmount = v => () => {
    const newSerial = serializeConfig(mode, extra, v, bundlesState, currency);
    navigate(`/${newSerial}`);
  };

  const closeTopTips = () => {
    const newExtra = {...extra, HIDE_TIPS: true};
    const newSerial = serializeConfig(mode, newExtra, floatAmount, bundlesState, currency);
    navigate(`/${newSerial}`);
  };

  return (
    <div>
      {!isPosKey &&
        <HeaderWithTopTips
          closeTips={closeTopTips}
          extra={extra}
          currency={currency}
        />
      }
      <form style={styles.columnsContainer(below800px)}>
        <div style={{marginBottom: theme.gutterMedium}}>
          <Typography variant="body2" style={styles.verticalMargin}>
            MODE
          </Typography>
          <OneColumn
            arr={denoms.filter(d => isNote(currency, d))}
            activeBundles={activeBundles}
            data={data}
            currency={currency}
            modeObj={mode}
            changeMode={changeMode}
            handleInputValChange={handleInputValChange}
            clearInput={clearInput}
          />
        </div>
        <div style={{marginBottom: theme.gutterMedium}}>
          {!below800px &&
            <Typography variant="body2" style={styles.verticalMargin}>
              MODE
            </Typography>
          }
          <OneColumn
            arr={denoms.filter(d => !isNote(currency, d))}
            activeBundles={activeBundles}
            data={data}
            currency={currency}
            modeObj={mode}
            changeMode={changeMode}
            handleInputValChange={handleInputValChange}
            clearInput={clearInput}
            startTabIndex={denoms.filter(d => isNote(currency, d)).length}
          />
        </div>
      </form>
      <Summary
        activeBundles={activeBundles}
        chooseFloatAmount={chooseFloatAmount}
        currency={currency}
        totalSum={totalSum}
        totalDenoms={totalDenoms}
        floatSpecified={floatAmount > 0}
        floatSum={floatObj.value}
        floatDenoms={floatObj.denoms}
        takingsSum={takingsSum}
        takingsDenoms={takingsDenoms}
        isPosKey={isPosKey}
      />
      {isPosKey &&
        <Catch
          totalSum={totalSum}
          floatSum={floatObj.value}
          takingsSum={takingsSum}
          totalDenoms={totalDenoms}
        />
      }
    </div>
  );
};

const styles = {
  columnsContainer: below800px => below800px ? null : {
    display: 'flex',
    justifyContent: 'space-between',
  },
  verticalMargin: {
    margin: `${theme.gutterMedium}px 0`,
  },
};

export default Home;

import {IconButton} from '@mui/material';
import {colorNames, theme} from '../helpers/theme';

const getColors = variant => {
  switch (variant) {
    case 'error':
      return {bg: colorNames.redBg, main: colorNames.red};
    case 'warning':
      return {bg: colorNames.orangeBg, main: colorNames.orange};
    case 'info':
      return {bg: colorNames.blueBg, main: colorNames.blue};
    case 'success':
      return {bg: colorNames.greenBg, main: colorNames.green};
    default:
      return {bg: colorNames.yellowBg, main: colorNames.yellow};
  }
};

const Infobox = ({buttonDisabled, children, icon, onIconClick, variant}) => (
  <div style={styles.root(getColors(variant))}>
    <IconButton
      style={styles.iconButton}
      onClick={onIconClick}
      disabled={buttonDisabled}
    >
      {icon}
    </IconButton>
    {children}
  </div>
);

const styles = {
  root: colors => ({
    position: 'relative',
    padding: theme.gutterBig,
    borderLeftStyle: 'solid',
    borderLeftWidth: 5,
    borderLeftColor: colors.main,
    background: colors.bg,
  }),
  iconButton: {
    position: 'absolute',
    top: theme.gutter,
    right: theme.gutter,
  },
};

export default Infobox;

const AbacusIcon = props => (
  <svg viewBox="0 0 200 200" width={30} {...props}>
    <path d="M44.68,50.76H81v5.48a6.61,6.61,0,0,0,6.6,6.61h11a6.61,6.61,0,0,0,6.61-6.61V50.76h20.18v5.48a6.6,6.6,0,0,0,6.6,6.61h11a6.6,6.6,0,0,0,6.6-6.61V50.76h4.61a3.53,3.53,0,0,0,3.54-3.54V43a3.54,3.54,0,0,0-3.54-3.54h-4.61V34a6.59,6.59,0,0,0-6.6-6.6H132a6.59,6.59,0,0,0-6.6,6.6v5.48H105.19V34a6.6,6.6,0,0,0-6.61-6.6h-11A6.6,6.6,0,0,0,81,34v5.48H44.68A3.54,3.54,0,0,0,41.14,43v4.21A3.53,3.53,0,0,0,44.68,50.76Z"/>
    <path d="M44.68,93H58.77v5.48a6.6,6.6,0,0,0,6.6,6.6h11A6.6,6.6,0,0,0,83,98.45V93h20.18v5.48a6.59,6.59,0,0,0,6.6,6.6h11a6.59,6.59,0,0,0,6.6-6.6V93h26.81a3.54,3.54,0,0,0,3.54-3.54V85.22a3.53,3.53,0,0,0-3.54-3.54H127.38V76.2a6.6,6.6,0,0,0-6.6-6.61h-11a6.6,6.6,0,0,0-6.6,6.61v5.48H83V76.2a6.61,6.61,0,0,0-6.61-6.61h-11a6.61,6.61,0,0,0-6.6,6.61v5.48H44.68a3.53,3.53,0,0,0-3.54,3.54v4.21A3.54,3.54,0,0,0,44.68,93Z"/>
    <path d="M44.68,135.15H81v5.48a6.61,6.61,0,0,0,6.6,6.61h11a6.61,6.61,0,0,0,6.61-6.61v-5.48h49a3.53,3.53,0,0,0,3.54-3.54V127.4a3.54,3.54,0,0,0-3.54-3.54h-49v-5.48a6.6,6.6,0,0,0-6.61-6.6h-11a6.6,6.6,0,0,0-6.6,6.6v5.48H44.68a3.54,3.54,0,0,0-3.54,3.54v4.21A3.53,3.53,0,0,0,44.68,135.15Z"/>
    <path d="M192.57,161.74H188.2a4.3,4.3,0,0,1-4.31-4.3V17.21a4.31,4.31,0,0,0-4.3-4.31H168.3a4.31,4.31,0,0,0-4.3,4.31V157.44a4.3,4.3,0,0,1-4.31,4.3H39.37a4.29,4.29,0,0,1-4.3-4.3V17.21a4.31,4.31,0,0,0-4.31-4.31H19.48a4.31,4.31,0,0,0-4.31,4.31V157.44a4.29,4.29,0,0,1-4.3,4.3H6.3A4.31,4.31,0,0,0,2,166.05v11.28a4.31,4.31,0,0,0,4.3,4.31H192.57a4.3,4.3,0,0,0,4.3-4.31V166.05A4.3,4.3,0,0,0,192.57,161.74Z"/>
  </svg>
);

const WeightIcon = props => (
  <svg viewBox="0 0 200 200" width={30} {...props}>
    <path d="M46.19,156.7c-6.38,0-12.76.15-19.12,0-7.16-.21-11.35-3.64-11.33-11.26q0-21.39,0-42.8c0-6.43,2.85-10.23,9.54-10.94C31.69,91,37.71,89,38.11,81.36s-4.87-10.6-11.55-11.43C22.84,69.47,21,67.6,21.23,64c.27-3.75,2.82-5,6.3-5q18.67,0,37.34,0c3.56,0,6,1.46,6.2,5.12S69.41,69.55,65.64,70c-6.72.76-12,4-11.51,11.46s6.51,9.62,13,10.23c6.74.64,9.66,4.82,9.75,11q.33,21.39,0,42.8c-.11,7.27-4.33,11.08-11.58,11.24C58.94,156.81,52.56,156.71,46.19,156.7Z"/>
    <path d="M85.68,130c0-3.93.2-7.88,0-11.81-.52-8.52,1-15,11.58-15.56,4.29-.21,7.16-3.72,7.23-8.09s-2.79-7.22-7.06-8.31c-3.17-.81-7.41-.79-6.8-5.48.67-5.19,5.3-4,8.72-4,8.18-.15,16.36-.09,24.53,0,3,0,6.59-.38,7.31,3.6S129,85.39,125.68,86c-5,.86-8.66,3.49-8.29,9s4.28,7.7,9.29,8.15c6.82.61,9.3,4.52,9.2,11-.16,10.6-.21,21.2,0,31.8.15,7.86-3.84,10.89-11.19,10.82-9.09-.08-18.18-.2-27.26,0-8.55.2-12.18-3.87-11.82-12.21.21-4.84,0-9.69,0-14.54Z"/>
    <path d="M144.43,135.7c0-3.13.15-6.28,0-9.41-.41-6.8.78-12,9.24-12.41,3.42-.17,5.71-3,5.77-6.45s-2.23-5.76-5.63-6.63c-2.53-.65-5.91-.63-5.43-4.37.54-4.14,4.23-3.16,7-3.21,6.51-.12,13-.07,19.56,0,2.38,0,5.25-.31,5.82,2.87s-1.7,4.06-4.36,4.52c-4,.68-6.9,2.78-6.61,7.18s3.41,6.14,7.41,6.5c5.44.49,7.41,3.61,7.33,8.78-.13,8.45-.16,16.9,0,25.35.12,6.27-3.06,8.69-8.93,8.63-7.24-.06-14.49-.15-21.73,0-6.82.16-9.71-3.08-9.42-9.73.17-3.86,0-7.73,0-11.6Z"/>
    <path d="M11.43,162.26H6.87a4.31,4.31,0,0,0-4.31,4.3v11.29a4.31,4.31,0,0,0,4.31,4.3H193.13a4.31,4.31,0,0,0,4.31-4.3V166.56a4.31,4.31,0,0,0-4.31-4.3Z"/>
    <rect x="94.35" y="134.07" width="11.29" height="116.6" rx="1.6" transform="translate(-92.37 292.37) rotate(-90)"/>
  </svg>
);

const Sygnet = props => (
  <svg fill="#3a74b9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
    <rect x="19.2" y="111.08" width="48.16" height="10.32" rx="0.85"/>
    <rect x="19.2" y="94.26" width="48.16" height="10.32" rx="0.85"/>
    <rect x="19.2" y="77.44" width="48.16" height="10.32" rx="0.85"/>
    <rect x="19.2" y="60.62" width="48.16" height="10.32" rx="0.85"/>
    <rect x="19.2" y="161.54" width="48.16" height="10.32" rx="0.85"/>
    <rect x="19.2" y="144.72" width="48.16" height="10.32" rx="0.85"/>
    <rect x="19.2" y="127.9" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="111.07" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="94.25" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="77.43" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="60.61" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="43.79" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="26.97" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="10.15" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="161.54" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="144.72" width="48.16" height="10.32" rx="0.85"/>
    <rect x="132.63" y="127.9" width="48.16" height="10.32" rx="0.85"/>
    <rect x="75.92" y="111.08" width="48.16" height="10.32" rx="0.85"/>
    <rect x="75.92" y="161.54" width="48.16" height="10.32" rx="0.85"/>
    <rect x="75.92" y="144.72" width="48.16" height="10.32" rx="0.85"/>
    <rect x="75.92" y="127.9" width="48.16" height="10.32" rx="0.85"/>
    <path d="M15.15,179.11H10.78c-2.28,0-4.12,1-4.12,2.32v6.09c0,1.29,1.84,2.33,4.12,2.33H189.22c2.28,0,4.12-1,4.12-2.33v-6.09c0-1.28-1.84-2.32-4.12-2.32Z"/>
  </svg>
);

const Logo = props => (
  <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2305.42 370.64" {...props}>
    <title>Daily Cashup</title>
    <desc>Daily Cashup logo</desc>
    <path d="M28.7,50.89a5.3,5.3,0,0,1,5.4-5.19h82.75q23.42,0,42,8.53a94,94,0,0,1,31.72,23.31,103.63,103.63,0,0,1,20.08,34.57,126.19,126.19,0,0,1,7,42.32,124.63,124.63,0,0,1-7.4,43.5,106.28,106.28,0,0,1-20.66,34.73,93.82,93.82,0,0,1-31.79,23q-18.56,8.29-41,8.29H34.1a5.3,5.3,0,0,1-5.4-5.19ZM67.08,227a5.3,5.3,0,0,0,5.4,5.19h35.26a78.88,78.88,0,0,0,28.63-5.08,63.13,63.13,0,0,0,22.77-15,68.65,68.65,0,0,0,15-24.25q5.37-14.39,5.37-33,0-20.33-5.45-35T159,95.61a59.93,59.93,0,0,0-22.76-14.16,85.51,85.51,0,0,0-28.47-4.62H72.48A5.3,5.3,0,0,0,67.08,82Z"/>
    <path d="M359.08,49.12l79,207.83a5.24,5.24,0,0,1-5.09,7H404.33a5.4,5.4,0,0,1-5.08-3.41l-21.79-57a5.4,5.4,0,0,0-5.08-3.41H298.31a5.36,5.36,0,0,0-5.07,3.43Q288,217.67,282.4,232t-10.85,28.49a5.36,5.36,0,0,1-5.07,3.43H238.34a5.23,5.23,0,0,1-5.08-7l79-207.83a5.42,5.42,0,0,1,5.09-3.42H354A5.41,5.41,0,0,1,359.08,49.12Zm-28.86,56-21.24,57a5.24,5.24,0,0,0,5.1,7h42.36a5.25,5.25,0,0,0,5.11-7l-21.13-57.06A5.49,5.49,0,0,0,330.22,105.12Z"/>
    <path d="M520.07,51V258.68a5.38,5.38,0,0,1-5.48,5.27H487.16a5.37,5.37,0,0,1-5.47-5.27V51a5.37,5.37,0,0,1,5.47-5.26h27.43A5.37,5.37,0,0,1,520.07,51Z"/>
    <path d="M614.32,51v176a5.37,5.37,0,0,0,5.47,5.27H718a5.37,5.37,0,0,1,5.47,5.27v21.22A5.37,5.37,0,0,1,718,264H581.41a5.37,5.37,0,0,1-5.47-5.27V51a5.37,5.37,0,0,1,5.47-5.26h27.44A5.37,5.37,0,0,1,614.32,51Z"/>
    <path d="M808.91,134.5l50.73-86.17a5.46,5.46,0,0,1,4.7-2.63h31.08a5.19,5.19,0,0,1,4.63,7.86l-76.47,122.8a4.93,4.93,0,0,0-.77,2.66v79.74a5.3,5.3,0,0,1-5.4,5.19H789.66a5.3,5.3,0,0,1-5.4-5.19V179a5,5,0,0,0-.76-2.66L707.17,53.55a5.19,5.19,0,0,1,4.64-7.85h31.12a5.43,5.43,0,0,1,4.67,2.59l51.95,86.25A5.5,5.5,0,0,0,808.91,134.5Z"/>
    <path d="M1043.23,151.78q0,22.38,5.77,38.8t15.21,27.22a60.64,60.64,0,0,0,21.39,16,59.69,59.69,0,0,0,24.15,5.24,51.71,51.71,0,0,0,22.76-4.93,60.93,60.93,0,0,0,18-13.06,72.71,72.71,0,0,0,12.76-18.54,92.15,92.15,0,0,0,6.39-17.33,5.36,5.36,0,0,1,5.22-4h28a5.25,5.25,0,0,1,5.29,6.32,131.06,131.06,0,0,1-10.54,28.34,102.8,102.8,0,0,1-20.82,28,92.8,92.8,0,0,1-29.43,18.78q-16.83,6.8-37.65,6.8-24.07,0-43.43-7.9a89.44,89.44,0,0,1-33-23q-13.66-15.09-21.06-37t-7.41-49.91a126.34,126.34,0,0,1,7-41.85,108,108,0,0,1,20.41-35.59,99.16,99.16,0,0,1,32.85-24.72q19.44-9.23,44.16-9.23,21.13,0,37.82,5.79a92.89,92.89,0,0,1,50.25,40.36,131.16,131.16,0,0,1,11.28,23.84,5.25,5.25,0,0,1-5.07,7h-28.77a5.43,5.43,0,0,1-5-3.37q-8.41-20.77-22.43-31.44-15.12-11.49-38.06-11.5a55.91,55.91,0,0,0-26.59,6.42,66,66,0,0,0-20.9,17.44,82.66,82.66,0,0,0-13.66,25.74A98.87,98.87,0,0,0,1043.23,151.78Z"/>
    <path d="M1343.7,49.13l79,207.83a5.24,5.24,0,0,1-5.09,7H1389a5.43,5.43,0,0,1-5.09-3.42l-21.78-57a5.43,5.43,0,0,0-5.09-3.42h-74.06a5.38,5.38,0,0,0-5.08,3.43Q1272.61,217.68,1267,232t-10.84,28.49a5.38,5.38,0,0,1-5.08,3.43H1223a5.24,5.24,0,0,1-5.09-7l79-207.83a5.4,5.4,0,0,1,5.08-3.42h36.66A5.4,5.4,0,0,1,1343.7,49.13Zm-28.85,56-21.25,57.06a5.24,5.24,0,0,0,5.1,7h42.37a5.24,5.24,0,0,0,5.1-6.95l-21.12-57.06A5.49,5.49,0,0,0,1314.85,105.12Z"/>
    <path d="M1475.92,187.61a5.44,5.44,0,0,1,5.41,4.72,61.27,61.27,0,0,0,4.31,15.85,52.4,52.4,0,0,0,10.49,16,45.37,45.37,0,0,0,15.29,10.4,49.61,49.61,0,0,0,19.35,3.68,82.81,82.81,0,0,0,17.81-1.88,45,45,0,0,0,15-6.1,31.81,31.81,0,0,0,10.41-11q3.92-6.81,3.91-16.82a21.12,21.12,0,0,0-5.62-14.86,48.7,48.7,0,0,0-14.71-10.56,142,142,0,0,0-20.82-8.06q-11.71-3.6-23.82-7.59t-23.84-8.84a88.48,88.48,0,0,1-20.81-12,56.52,56.52,0,0,1-14.72-17.13q-5.61-10-5.61-24.09,0-14.4,5.85-25.42a52.72,52.72,0,0,1,16.51-18.54,77.53,77.53,0,0,1,25.37-11.35A128.28,128.28,0,0,1,1528,40.23a113.31,113.31,0,0,1,27.65,3.05,81.89,81.89,0,0,1,21.06,8.29,61.45,61.45,0,0,1,15.2,12.2,69.09,69.09,0,0,1,10.17,14.95A74.14,74.14,0,0,1,1608,95.14q1.24,5.34,2,10.55a5.36,5.36,0,0,1-5.42,6h-28.3a5.46,5.46,0,0,1-5.4-4.62,48.89,48.89,0,0,0-4.13-13.53,39.45,39.45,0,0,0-9.52-12.67A37.7,37.7,0,0,0,1544,73.48,51.34,51.34,0,0,0,1528,71.05a76.18,76.18,0,0,0-15,1.49,43.39,43.39,0,0,0-13.26,4.85,28.32,28.32,0,0,0-9.52,8.76,23,23,0,0,0-3.66,13.22,21.81,21.81,0,0,0,5.62,15.25,50.22,50.22,0,0,0,14.71,10.8,136.15,136.15,0,0,0,20.82,8.29q11.71,3.67,23.91,7.67t23.91,8.91a94.62,94.62,0,0,1,20.81,11.89A56.13,56.13,0,0,1,1611,178.93q5.6,9.76,5.6,23.54,0,16.74-6.42,29.26a58.68,58.68,0,0,1-17.89,20.88,81.46,81.46,0,0,1-27.16,12.6,128.69,128.69,0,0,1-30.46,4.17,101.32,101.32,0,0,1-26.23-2.54A90.91,90.91,0,0,1,1470,247.53a83.36,83.36,0,0,1-20.17-25.74,76,76,0,0,1-7.91-28.62,5.32,5.32,0,0,1,5.47-5.56Z"/>
    <path d="M1698.81,51V130.4a5.37,5.37,0,0,0,5.47,5.27H1796a5.37,5.37,0,0,0,5.47-5.27V51a5.37,5.37,0,0,1,5.48-5.26h27.43a5.37,5.37,0,0,1,5.47,5.26V258.69a5.37,5.37,0,0,1-5.47,5.27h-27.43a5.38,5.38,0,0,1-5.48-5.27v-87.1a5.37,5.37,0,0,0-5.47-5.26h-91.68a5.37,5.37,0,0,0-5.47,5.26v87.1a5.37,5.37,0,0,1-5.47,5.27H1665.9a5.37,5.37,0,0,1-5.47-5.27V51a5.37,5.37,0,0,1,5.47-5.26h27.44A5.37,5.37,0,0,1,1698.81,51Z"/>
    <path d="M1927.78,45.71a5.3,5.3,0,0,1,5.4,5.19V190.27a51.07,51.07,0,0,0,3.89,20.42,46,46,0,0,0,10.41,15.17,44.25,44.25,0,0,0,15,9.39,50.18,50.18,0,0,0,17.81,3.2,63,63,0,0,0,19.27-3,50.27,50.27,0,0,0,16.67-8.92A44,44,0,0,0,2028,211.78q4.4-8.84,4.39-20.73V50.9a5.3,5.3,0,0,1,5.4-5.19h27.59a5.3,5.3,0,0,1,5.4,5.19V189.33q0,19.56-7.57,34.5a76.36,76.36,0,0,1-20.08,25.11,86.86,86.86,0,0,1-28.79,15.33,110.68,110.68,0,0,1-33.67,5.16,102.59,102.59,0,0,1-32.12-5.08,81.29,81.29,0,0,1-27.48-15.18A73.58,73.58,0,0,1,1902,223.91q-7.15-15.18-7.16-35.21V50.9a5.3,5.3,0,0,1,5.4-5.19Z"/>
    <path d="M2119.72,50.9a5.3,5.3,0,0,1,5.4-5.19h84.38q17.57,0,31.06,5.78a70.59,70.59,0,0,1,22.69,15.1,65.06,65.06,0,0,1,14,21A61.14,61.14,0,0,1,2282,111a63.23,63.23,0,0,1-4.79,24.09,62.35,62.35,0,0,1-14.07,20.88,69.46,69.46,0,0,1-22.78,14.71q-13.48,5.56-30.89,5.56h-46a5.3,5.3,0,0,0-5.4,5.19v77.39a5.29,5.29,0,0,1-5.39,5.19h-27.59a5.3,5.3,0,0,1-5.4-5.19ZM2158.1,140a5.3,5.3,0,0,0,5.4,5.19h37.87q11.87,0,19.92-2.74a35,35,0,0,0,13-7.43,27.25,27.25,0,0,0,7.15-11,41.41,41.41,0,0,0,2.2-13.69,32.31,32.31,0,0,0-2.77-13.38,29.24,29.24,0,0,0-8.12-10.63,38.92,38.92,0,0,0-13.26-7,60,60,0,0,0-18.13-2.5H2163.5a5.3,5.3,0,0,0-5.4,5.19Z"/>
    <rect x="28.28" y="301.27" width="2246.98" height="32.61" rx="0.85"/>
  </svg>
);

export {AbacusIcon, Logo, Sygnet, WeightIcon};

const colorNames = {
  black: '#000',
  offWhite: '#f0f0f0',
  purple: '#8c27c2',
  disabledGrey: 'rgba(0, 0, 0, 0.26)',

  // Infobox colors
  yellow: '#ffe46e',
  yellowBg: '#fff9de',
  red: '#f44336',
  redBg: '#ffebee',
  orange: '#ff9800',
  orangeBg: '#fff3e0',
  blue: '#1976d2',
  blueBg: '#e3f2fd',
  green: '#4caf50',
  greenBg: '#e8f5e9',
};

const gutterBase = 4;
const theme = {
  gutterSmall: gutterBase,
  gutter: gutterBase * 2,
  gutterMedium: gutterBase * 3,
  gutterBig: gutterBase * 4,
};

export {colorNames, theme};

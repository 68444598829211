import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import Infobox from '../Infobox';
import {setCurrency} from '../../features/mainSlice';
import {CURRENCIES, SYMBOLS} from '../../helpers/common';
import {theme} from '../../helpers/theme';

const HeaderWithTopTips = ({closeTips, extra, currency}) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [tempCurrency, setTempCurrency] = useState(currency);
  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCancel = () => {
    setOpenDialog(false);
    setTempCurrency(currency);
  };
  
  const handleSubmit = () => {
    setOpenDialog(false);
    dispatch(setCurrency(tempCurrency));
  };

  const handleTempCurrencyChange = e => {
    setTempCurrency(e.target.value);
  };

  return (
    <>
      <div style={styles.topLine}>
        <Typography variant="body2">
          Performing cashup in <strong>{currency}</strong>
        </Typography>
        <IconButton onClick={handleOpenDialog} style={{marginLeft: theme.gutter}}>
          <EditIcon />
        </IconButton>
      </div>
      {!extra['HIDE_TIPS'] &&
        <Infobox variant="info" icon={<ClearIcon />} onIconClick={closeTips}>
          <Typography variant="h6">
            Quick start
          </Typography>
          <Typography variant="body2">
            This website is a tool that helps small retail businesses perform their daily counting of cash.<br />Feel free to use it however you like.
          </Typography>
          <br />
          <Typography variant="body1">
            &bull; you can weigh coins instead of counting but remember to use a good precision scale (like 0.1g)<br />
            &bull; double check the banknotes as they make a huge difference
          </Typography>
        </Infobox>
      }
      <Dialog style={styles.popup} fullWidth open={openDialog} onClose={handleCancel}>
        <DialogTitle>Currency</DialogTitle>
        <DialogContent>
        <FormControl style={{width: '100%'}}>
          <Select
            onChange={handleTempCurrencyChange}
            value={tempCurrency}
          >
          {CURRENCIES.map(c => <MenuItem key={c} value={c}>{`${c} (${SYMBOLS[c].lg})`}</MenuItem>)}
          </Select>
        </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={handleCancel} 
          color="primary">
            Cancel
          </Button>
          <Button 
          onClick={handleSubmit} 
          color="primary"
          variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  topLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.gutterMedium,
    justifyContent: 'flex-end',
  },
  popup: {
    maxWidth: 400,
    margin: 'auto',
  },
};

export default HeaderWithTopTips;
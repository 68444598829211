import {useSelector, useDispatch} from 'react-redux';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import CopyIcon from '@mui/icons-material/ContentCopy';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';

import Infobox from '../components/Infobox';

import {
  selectBundles,
  selectCurrency,
  selectExtra,
  selectFloatAmount,
  selectMode,
  setBundle,
  setCurrency,
  setExtra,
  setFloatAmount,
  setMode,
  setSnackbar,
} from '../features/mainSlice.js';

import {colorNames, theme} from '../helpers/theme';
import {
  BUNDLES,
  CURRENCIES,
  DENOMINATIONS,
  MAIN_URL,
  SYMBOLS,
  WEIGH,
  WEIGHTS,
  copyToClipboard,
  formatPrice,
  serializeConfig,
} from '../helpers/common';

const EXTRA_CONFIG_MSG = {
  HIDE_TIPS: `Hide the 'top tips' dialog`,
};

const OneRow = props => {
  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency);
  const mode = useSelector(selectMode);
  const weights = WEIGHTS[currency];

  const label = formatPrice(props.denom, currency, 'auto');
  const denomType = !weights[props.denom] ? 'note' : 'coin';

  const handleModeChange = e => {
    dispatch(setMode({
      id: props.denom,
      value: e.target.value,
    }));
  };

  return (
    <div style={styles.oneRow}>
      <FormControl style={styles.input}>
        <InputLabel>{label} {denomType === 'note' ? 'banknote' : 'coin'}</InputLabel>
        <Select
          onChange={handleModeChange}
          value={mode[props.denom]}
          label={`${label} ${denomType === 'note' ? 'banknote' : 'coin'}`}
        >
          <MenuItem value={'SKIP_HIDE'}>Skip and hide</MenuItem>
          <MenuItem value={'DISABLE'}>Disable but show</MenuItem>
          <MenuItem value={'COUNT'}>Count {denomType}s</MenuItem>
          {denomType === 'coin' &&
            <MenuItem value={'WEIGH'}>Weigh coins</MenuItem>}
        </Select>
      </FormControl>
    </div>
  )
}

const makeLink = serial => `${MAIN_URL}/${serial}`;

const Config = () => {
  const dispatch = useDispatch();
  const bundlesState = useSelector(selectBundles);
  const currency = useSelector(selectCurrency);
  const extra = useSelector(selectExtra);
  const floatAmount = useSelector(selectFloatAmount);
  const mode = useSelector(selectMode);
  const serial = serializeConfig(mode, extra, floatAmount, bundlesState, currency);

  const bundles = BUNDLES[currency];
  const extraOptions = Object.keys(extra);

  const clearFloatAmount = () => {
    dispatch(setFloatAmount(''));
  };

  const changeFloatAmount = e => {
    dispatch(setFloatAmount(e.target.value));
  };

  const handleCurrencyChange = e => {
    dispatch(setCurrency(e.target.value));
  };

  const handleChangeCheckbox = e => {
    const extraToggle = extra[e.target.value];
    dispatch(setExtra({
      id: e.target.value,
      value: !extraToggle,
    }));
  };

  const handleChangeBundle = e => {
    const bundleToggle = bundlesState[e.target.value];
    dispatch(setBundle({
      idx: e.target.value,
      value: !bundleToggle,
    }));
  };

  const onCopyIconClick = () => {
    copyToClipboard(makeLink(serial));
    dispatch(setSnackbar({
      open: true,
      type: 'success',
      message: 'Copied into the clipboard.',
    }));
  };

  return (
    <div style={styles.mainContent}>
      <Infobox variant="info">
        <Typography variant="body1" style={styles.text}>
          You can use this link to share the configuration with others:
        </Typography>
        <Link style={styles.link}>{makeLink(serial)}</Link>
        <IconButton onClick={onCopyIconClick}>
          <CopyIcon />
        </IconButton>
      </Infobox>
      <Typography variant="h6" style={styles.heading}>
        Currency
      </Typography>
      <FormControl style={styles.input}>
        <Select
          onChange={handleCurrencyChange}
          value={currency}
        >
          {CURRENCIES.map(c => <MenuItem key={c} value={c}>{`${c} (${SYMBOLS[c].lg})`}</MenuItem>)}
        </Select>
      </FormControl>
      <Typography variant="h6" style={styles.heading}>
        Denominations configuration
      </Typography>
      {DENOMINATIONS[currency].map(v =>
        <OneRow denom={v} key={v} />
      )}
      <Typography variant="h6" style={styles.heading} id="floatSection">
        Float amount
      </Typography>
      <TextField
        variant="outlined"
        value={floatAmount}
        onChange={changeFloatAmount}
        style={styles.input}
        type="number"
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                {formatPrice(floatAmount, currency)}
              </InputAdornment>
              {floatAmount &&
                <IconButton onClick={clearFloatAmount}>
                  <ClearIcon />
                </IconButton>
              }
            </> 
          ),
        }}
      />
      <Typography variant="h6" style={styles.heading}>Additional settings</Typography>
      {bundles.map( (denoms, idx) => {
        let count = 0;     
        const prices = denoms.map(d => formatPrice(d, currency, 'auto'));
        denoms.forEach(denom => {
          count = mode[denom] === WEIGH ? count + 1 : count;
        });
        const isDisable = count < denoms.length;

        if (isDisable && bundlesState[idx]) {
          dispatch(setBundle({
            idx: idx,
            value: false,
          }));
        };

        return (
          <div style={styles.checkItem} key={idx}>
            <FormControlLabel
              control={<Checkbox disabled={isDisable} checked={bundlesState[idx]} value={idx} onChange={handleChangeBundle} />}
              label={`Weigh ${prices.join(', ')} together`}
            />
            {isDisable &&
              <div style={styles.warBox}>
                <WarningIcon style={{marginRight: theme.gutterSmall}} />
                <Typography variant="body2" style={styles.text}>
                  {denoms.length > 2 
                      ? `Choose 'Weigh coins' for ${prices.join(', ')} to enable this option` 
                      : `Choose 'Weigh coins' for both ${prices[0]} & ${prices[1]} to enable this option`}
                </Typography>
              </div>
            }
          </div>
        );
      })}
      {extraOptions.map(extraKey => (
        <div style={styles.checkItem} key={extraKey}>
          <FormControlLabel
            control={<Checkbox checked={extra[extraKey]} value={extraKey} onChange={handleChangeCheckbox} />}
            label={EXTRA_CONFIG_MSG[extraKey]}
          />
        </div>
      ))}
    </div>
  )
}

const styles = {
  checkItem: {
    marginTop: theme.gutter,
  },
  mainContent: {
    marginTop: theme.gutterBig,
  },
  heading: {
    marginTop: theme.gutterMedium * 2,
    marginBottom: theme.gutter,
  },
  oneRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.gutterMedium,
  },
  input: {
    width: '100%',
    maxWidth: 350,
  },
  warBox: {
    color: colorNames.orange,
    display: 'flex',
    marginLeft: theme.gutterMedium * 2,
  },
  text: {
    hyphens: 'auto',
  },
  link: {
    wordBreak: 'break-all',
  }
}

export default Config;

/** @jsxImportSource @emotion/react */

import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import {setSnackbar} from '../../features/mainSlice';

import {formatPrice} from '../../helpers/common';
import {theme} from '../../helpers/theme';

const LABELS = ['Left in the till', 'Taken out'];
const SWAP = {0: 1, 1: 0};

const getDenomsNumberObj = totalDenoms => {
  const tR = {};
  for (const key in totalDenoms) {
    tR[key] = totalDenoms[key].amount / key;
  }
  return tR;
};

const isDataConsistent = ({denoms, leftInTill, takenOut}) => {
  let sum = 0;
  for (const denom in denoms) {
    sum += denoms[denom] * denom;
  }
  return sum === leftInTill + takenOut;
};

const putMoneyKeepTot = (data, idx, value) => {
  const tot = data.reduce((acc, v) => acc + v * 1, 0);
  const tR = [...data];
  tR[idx] = value;
  tR[SWAP[idx]] = (tot - tR[idx]).toString();
  return tR;
};

const Catch = ({totalSum, floatSum, totalDenoms}) => {
  const [data, setData] = useState([]); // string[]
  const dispatch = useDispatch();

  // taken out should be positive
  const takenOut = totalSum - floatSum;

  useEffect(() => {
    setData([floatSum.toString(), takenOut.toString()]);
  }, [floatSum, takenOut]);

  const handleValueChange = idx => evt => {
    setData(state => {
      const value = +evt.target.value > totalSum
        ? totalSum.toString()
        : evt.target.value;
      return putMoneyKeepTot(state, idx, value);
    });
  };

  const handleKeyPress = evt => {
    if (!/[0-9]/.test(evt.key)) evt.preventDefault();
  };

  const clearFloatAmount = idx => () =>
    setData(data => putMoneyKeepTot(data, idx, ''));

  const handleLog = () => {
    const logData = {
      denoms: getDenomsNumberObj(totalDenoms),
      leftInTill: +data[0],
      takenOut: +data[1],
    };
    
    if (!isDataConsistent(logData)) {
      dispatch(setSnackbar({
        open: true,
        type: 'error',
        message: 'The data is inconsistent. Total money in cash should be equal to sum of "Left in the till" and "Taken out"',
      }));
      return;
    }
    
    fetch(`${process.env.REACT_APP_API_URL}cashup?ut=${process.env.REACT_APP_POS_KEY}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(logData),
    })
      .then(res => res.json())
      .then(json => {
        let logInfo;
        if (json.d === 'ok') {
          logInfo = {
            open: true,
            type: 'success',
            message: 'Cash status was successfully logged.',
          };
        }
        else {
          logInfo = {
            open: true,
            type: 'error',
            message: 'Error occurred during cash status logging.',
          };
        }
        
        dispatch(setSnackbar(logInfo));
      });
  };

  return (
    <div css={styles.root}>
      <Typography variant="h6" css={{marginBottom: theme.gutterBig}}>
        Catch
      </Typography>
      <Grid container spacing={2}>
        {data.map((el, idx) => (
          <Grid item key={idx} css={{flexGrow: 1}}>
            <TextField
              label={LABELS[idx]}
              value={el}
              onChange={handleValueChange(idx)}
              onKeyPress={handleKeyPress}
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      {formatPrice(el, 'GBP')}
                    </InputAdornment>
                    {!!el &&
                      <IconButton onClick={clearFloatAmount(idx)}>
                        <ClearIcon />
                      </IconButton>
                    }
                  </> 
                ),
              }}
            />
          </Grid>
        ))}
        <Grid item css={styles.button}>
          <Button onClick={handleLog} variant="contained">Log</Button>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = {
  root: {
    marginTop: theme.gutterBig,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default Catch;

import {forwardRef} from 'react';
import NumberFormat from 'react-number-format';

import {
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';

import {AbacusIcon, WeightIcon} from '../../helpers/Icons';
import {colorNames, theme} from '../../helpers/theme';
import {
  calculateMoney,
  formatPrice,
  isNote,
} from '../../helpers/common';

const NumberFormatCustom = forwardRef((props, ref) => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix={props.suffix}
    />
  );
});

const OneRow = ({
  activeBundles,
  changeMode,
  clearInput,
  currency,
  data,
  denom,
  modeObj,
  onValueChange,
  tabIndex,
}) => {
  const classes = useStyles();
  const mode = modeObj[denom];
  const below600px = useMediaQuery('(max-width:599px)', {noSsr: true});

  let label;
  if (data[denom].byWeight && activeBundles.mins.includes(denom)) {
    const prices = activeBundles.denoms[denom].map(bDenom => formatPrice(bDenom, currency, 'auto'));
    label = `${prices.join(' + ')} (weight)`;
  }
  else
    label = formatPrice(denom, currency, 'auto') +
    (data[denom].byWeight ? ' (weight)' : ' (count)');

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <IconButton onClick={changeMode(denom)} disabled={mode === 'DISABLE' || isNote(currency, denom)}>
        {data[denom].byWeight
          ? <WeightIcon style={styles.icon(mode)} />
          : <AbacusIcon style={styles.icon(mode, isNote(currency, denom))} />
        }
      </IconButton>
      <TextField
        variant="outlined"
        value={data[denom].value}
        onChange={onValueChange(denom)}
        label={label}
        style={styles.input(below600px)}
        className={data[denom].byWeight ? classes.purpleBorder : null}
        disabled={mode === 'DISABLE'}
        onKeyPress={data[denom].byWeight ? null :
          (event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          })
        }
        InputProps={{
          style: mode === 'DISABLE' || data[denom].value === '' ? null : {paddingRight: 0},
          inputComponent: NumberFormatCustom,
          id: `${currency}${denom}${data[denom].byWeight ? 'w' : 'c'}`,
          endAdornment: (
            (data[denom].value !== '') ? (
                <>
                  <InputAdornment position="end">
                    {formatPrice(
                      calculateMoney(data[denom].byWeight, denom, data[denom].value, currency, activeBundles.denoms[denom]),
                      currency
                    )}
                  </InputAdornment>
                  {mode !== 'DISABLE' &&
                    <IconButton onClick={clearInput(denom)}>
                      <ClearIcon />
                    </IconButton>
                  }
                </>
              ) : (
                <InputAdornment
                  position="end"
                >
                  ?
                </InputAdornment>
              )
          ),
        }}
        inputProps={{
          suffix: data[denom].byWeight ? 'g' : '',
          tabIndex,
          maxLength: 7,
          pattern: "[0-9]*",
        }}
        InputLabelProps={mode === 'DISABLE' ? {shrink: true} : null}
      />
    </div>
  );
};

const useStyles = makeStyles({
  purpleBorder: {
    '& label.Mui-focused': {
      color: colorNames.purple,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: colorNames.purple,
      },
    },
  },
});

const styles = {
  icon: (mode, isNote)=> ({
    fill: (mode === 'DISABLE' || isNote) && colorNames.disabledGrey,
  }),
  input: below600px => ({
    width: below600px ? '100%' : 300,
    marginLeft: theme.gutterBig,
  }),
};

export default OneRow;

import OneRow from "./OneRow";
import {theme} from '../../helpers/theme';

const OneColumn = ({
  arr,
  activeBundles,
  changeMode,
  clearInput,
  currency,
  data,
  modeObj,
  handleInputValChange,
  startTabIndex,
}) => (
  <div style={styles.inputsContainer}>
    {arr.map((denom, index) => {
      return data[denom] ? (
        <OneRow
          activeBundles={activeBundles}
          denom={denom}
          data={data}
          currency={currency}
          modeObj={modeObj}
          changeMode = {changeMode}
          onValueChange={handleInputValChange}
          clearInput={clearInput}
          tabIndex={startTabIndex ? startTabIndex + index + 1 : index + 1}
          key={denom}
        />
      ) : null;
    })}
  </div>
);

const styles = {
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.gutterMedium,
  },
};

export default OneColumn;

import {useNavigate} from 'react-router-dom';

import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {colorNames, theme} from '../helpers/theme';

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const goTo = (path, state) => async () => {
    await navigate('/config');
    navigate(path, {state, replace: true});
  };

  return (
    <div style={styles.root}>
      <div style={styles.insideFrame}>
        <Typography variant="body2" style={{flex: 3, color: colorNames.offWhite}}>
          Created by <strong>Sets Apart Limited</strong><br />
          Offered on <strong>MIT licence</strong><br />
          <strong>Cookieless</strong> by design
        </Typography>
        <div style={{flex: 2}}>
          <Typography onClick={goTo('/config')} variant="body2" className={classes.link}>
            Configure the cashup
          </Typography>
          <Typography onClick={goTo('/contact-us')} variant="body2" className={classes.link}>
            Contact us
          </Typography>
          <Typography onClick={goTo('/contact-us', {report: 'report-bug'})} variant="body2" className={classes.link}>
            Report a bug
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  link: {
    width: 150,
    color: colorNames.offWhite,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const styles = {
  root: {
    background: colorNames.black,
  },
  insideFrame: {
    display: 'flex',
    columnGap: theme.gutterBig * 2,
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    padding: theme.gutterBig,
  },
};

export default Footer;
